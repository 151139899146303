import * as React from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import { PageLoaderFallback } from '../../layout/PageLoaderFallback';
import MeglerBergen from './veientilsolgt/bergen/MeglerBergen';
import MeglerStvng from './veientilsolgt/stavanger/MeglerStavanger';
import Megler from './veientilsolgt/Megler';

const DenUtlosendeFaktor = loadable(
  () => import(/* webpackPrefetch: true */ './utlosendefaktor'),
  {
    fallback: <PageLoaderFallback />
  }
);
const HelgeLandsKysten = loadable(
  () =>
    import(/* webpackPrefetch: true */ './helgelandskysten/HelgeLandsKysten'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SelgeEllerKjopeFritid = loadable(
  () =>
    import(/* webpackPrefetch: true */ './kristiansand/SelgeEllerKjopeFritid'),
  {
    fallback: <PageLoaderFallback />
  }
);
const FritidsArtikkel = loadable(
  () => import(/* webpackPrefetch: true */ './fritid/FritidsArtikkel'),
  {
    fallback: <PageLoaderFallback />
  }
);
const FritidsArtikkel2 = loadable(
  () => import(/* webpackPrefetch: true */ './fritid/FritidsArtikkel2'),
  {
    fallback: <PageLoaderFallback />
  }
);
const FritidsArtikkel3 = loadable(
  () => import(/* webpackPrefetch: true */ './fritid/FritidsArtikkel3'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VikoyrIndex = loadable(
  () => import(/* webpackPrefetch: true */ './vikoyr/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const FilmFest = loadable(
  () => import(/* webpackPrefetch: true */ './kvadrat/filmfest'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Raadgivning = loadable(
  () => import(/* webpackPrefetch: true */ './raadgiving/RaadGiving'),
  {
    fallback: <PageLoaderFallback />
  }
);

const KampanjeVinnEnMeglerBergen = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './vinnmegler/KampanjeVinnEnMeglerBergen'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const KampanjeVinnEnMeglerStavanger = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './vinnmegler/KampanjeVinnEnMeglerStavanger'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const KampanjeVinnEnMeglerSogn = loadable(
  () =>
    import(/* webpackPrefetch: true */ './vinnmegler/KampanjeVinnEnMeglerSogn'),
  {
    fallback: <PageLoaderFallback />
  }
);

const KampanjeVinnEnMeglerJaeren = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './vinnmegler/KampanjeVinnEnMeglerJaeren'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const KampanjeVinnEnMeglerSandnes = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './vinnmegler/KampanjeVinnEnMeglerSandnes'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const MeglerStjerne = loadable(
  () => import(/* webpackPrefetch: true */ './meglerstjerne/MeglerStjerne'),
  {
    fallback: <PageLoaderFallback />
  }
);

const LandingssideKvadrat = loadable(
  () => import(/* webpackPrefetch: true */ './kvadrat/Kvadrat'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Presisjon = loadable(
  () => import(/* webpackPrefetch: true */ './precision/Precision'),
  {
    fallback: <PageLoaderFallback />
  }
);
const PresisjonNybygg = loadable(
  () => import(/* webpackPrefetch: true */ './precision/PrecisjonNybygg'),
  {
    fallback: <PageLoaderFallback />
  }
);
const Fritid = loadable(
  () => import(/* webpackPrefetch: true */ './precision/Fritid'),
  {
    fallback: <PageLoaderFallback />
  }
);

/* Veien til solgt hovedside */
const VeienTilSolgt = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/VeienTilSolgt'),
  {
    fallback: <PageLoaderFallback />
  }
);
const VeienTilSolgtOslo = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/oslo/oslo'),
  {
    fallback: <PageLoaderFallback />
  }
);
const VeienTilSolgtMeglerOslo = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/oslo/MeglerOslo'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VeienTilSolgtStylistOslo = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/oslo/StylistOslo'),
  {
    fallback: <PageLoaderFallback />
  }
);

/* Bergen */

const VeienTilSolgtBergen = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/bergen/bergen'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VeienTilSolgtBergenMegler = loadable(
  () =>
    import(/* webpackPrefetch: true */ './veientilsolgt/bergen/MeglerBergen'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VeienTilSolgtBergenStylist = loadable(
  () =>
    import(/* webpackPrefetch: true */ './veientilsolgt/bergen/StylistBergen'),
  {
    fallback: <PageLoaderFallback />
  }
);

/* Stavanger */

const VeienTilSolgtStvg = loadable(
  () =>
    import(/* webpackPrefetch: true */ './veientilsolgt/stavanger/stavanger'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VeienTilSolgtStnvgMegler = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './veientilsolgt/stavanger/MeglerStavanger'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const VeienTilSolgtStvgStylist = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './veientilsolgt/stavanger/StylistStavanger'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const SkalDuSelge = loadable(
  () => import(/* webpackPrefetch: true */ './skalduselge/SkalDuSelge'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SkalDuSelgeArtikkel1 = loadable(
  () =>
    import(/* webpackPrefetch: true */ './skalduselge/SkalDuSelgeArtikkel1'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SkalDuSelgeArtikkel2 = loadable(
  () =>
    import(/* webpackPrefetch: true */ './skalduselge/SkalDuSelgeArtikkel2'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SkalDuSelgeArtikkel3 = loadable(
  () =>
    import(/* webpackPrefetch: true */ './skalduselge/SkalDuSelgeArtikkel3'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SkalDuSelgeArtikkel4 = loadable(
  () =>
    import(/* webpackPrefetch: true */ './skalduselge/SkalDuSelgeArtikkel4'),
  {
    fallback: <PageLoaderFallback />
  }
);
const Fremsnakk = loadable(
  () => import(/* webpackPrefetch: true */ './fremsnakk/Fremsnakk'),
  {
    fallback: <PageLoaderFallback />
  }
);
const Forstegangsselgeren = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './Forstegangsselgeren/Forstegangsselgeren'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const Forstegangsselgerenmeta = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './Forstegangsselgeren/Forstegangsselgeren-meta'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const ForstegangsselgerenHovedside = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './Forstegangsselgeren/ForstegangsselgerenHovedside'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const ForstegangsselgerenOppusingsObjekt = loadable(
  () => import('./Forstegangsselgeren/ForstegangsselgerenOppusingsObjekt'),
  {
    fallback: <PageLoaderFallback />
  }
);
const ForstegangsselgerenFlytte = loadable(
  () => import('./Forstegangsselgeren/ForstegangsselgerenFlytte'),
  {
    fallback: <PageLoaderFallback />
  }
);
const ForstegangsselgerenArv = loadable(
  () => import('./Forstegangsselgeren/ForstegangsselgerenArvHytte'),
  {
    fallback: <PageLoaderFallback />
  }
);
const ForstegangsselgerenSkillsmisse = loadable(
  () => import('./Forstegangsselgeren/ForstegangsselgerenSkillsmisseBrudd'),
  {
    fallback: <PageLoaderFallback />
  }
);

const PanoramaKampanje = loadable(() => import('./panorama/Index'));
const BoligVerdi = loadable(() => import('./kristiansand/BoligVerdi'));
const Sandnes = loadable(() => import('./sandnes/Sandnes'));
/* const SchultzGate = loadable(() => import('./schultzgate/Shultzgate')); */
const VinnBoligStyling = loadable(() => import('./boligstyling/BoligStyling'));
const NewKvadratApp = loadable(() => import('./kvadrat/Kvadrat'));
const FremsnakkSandnes = loadable(() => import('./sandnes/Fremsnakk'));
const PanoramaSkalDuSelgeBolig = loadable(
  () => import('./skalduselgebolig/PanoramaSkalDuSelgeBolig')
);

const NoMatch = loadable(() => import(/* webpackPrefetch: true */ '../404Old'));

const Kampanje: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/kampanje" component={DenUtlosendeFaktor} />
        <Route
          exact
          path="/kampanje/panorama/skal-du-selge-bolig"
          component={PanoramaSkalDuSelgeBolig}
        />
        <Route
          exact
          path="/kampanje/helgelandskysten"
          component={HelgeLandsKysten}
        />
        <Route
          exact
          path="/kampanje/selgekjopefritidsbolig"
          component={SelgeEllerKjopeFritid}
        />
        <Route
          exact
          path="/kampanje/fritidsartikkel"
          component={FritidsArtikkel}
        />
        <Route
          exact
          path="/kampanje/fritidsartikkel2"
          component={FritidsArtikkel2}
        />
        <Route
          exact
          path="/kampanje/fritidsartikkel3"
          component={FritidsArtikkel3}
        />

        <Route path="/kampanje/vikoyr" component={VikoyrIndex} />
        <Route
          exact
          path="/kampanje/raadgivning/selge-eller-kjope-leilighet-forst"
          component={Raadgivning}
        />

        <Route
          exact
          path="/kampanje/panorama/:place?/:slug?"
          component={PanoramaKampanje}
        />
        <Route
          exact
          path="/kampanje/sogn/vinn-en-megler"
          component={KampanjeVinnEnMeglerSogn}
        />
        <Route
          exact
          path="/kampanje/bergen/vinn-en-megler"
          component={KampanjeVinnEnMeglerBergen}
        />
        <Route
          exact
          path="/kampanje/stavanger/vinn-en-megler"
          component={KampanjeVinnEnMeglerStavanger}
        />
        <Route
          exact
          path="/kampanje/jaeren/vinn-en-megler"
          component={KampanjeVinnEnMeglerJaeren}
        />
        <Route
          exact
          path="/kampanje/sandnes/vinn-en-megler"
          component={KampanjeVinnEnMeglerSandnes}
        />
        <Route exact path="/kampanje/fremsnakk" component={Fremsnakk} />
        <Route
          exact
          path="/kampanje/fremsnakk/sandnes"
          component={FremsnakkSandnes}
        />

        <Route exact path="/kampanje/meglerstjerne" component={MeglerStjerne} />
        <Route exact path="/kampanje/kvadrat" component={NewKvadratApp} />
        <Route exact path="/kampanje/presisjon" component={Presisjon} />
        <Route
          exact
          path="/kampanje/presisjon/nybygg"
          component={PresisjonNybygg}
        />
        <Route exact path="/kampanje/presisjon/fritid" component={Fritid} />

        <Route exact path="/kampanje/kvadrat" component={LandingssideKvadrat} />

        {/* Veien til solgt hovedside */}
        <Route
          exact
          path="/kampanje/veien-til-solgt"
          component={VeienTilSolgt}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/oslo"
          component={VeienTilSolgtOslo}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/oslo/megler"
          component={VeienTilSolgtMeglerOslo}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/oslo/stylist"
          component={VeienTilSolgtStylistOslo}
        />

        {/* Bergen */}
        <Route
          exact
          path="/kampanje/veien-til-solgt/bergen"
          component={VeienTilSolgtBergen}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/bergen/megler"
          component={VeienTilSolgtBergenMegler}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/bergen/stylist"
          component={VeienTilSolgtBergenStylist}
        />

        {/* Stavanger */}
        <Route
          exact
          path="/kampanje/veien-til-solgt/stavanger"
          component={VeienTilSolgtStvg}
        />

        <Route
          exact
          path="/kampanje/veien-til-solgt/stavanger/megler"
          component={VeienTilSolgtStnvgMegler}
        />
        <Route
          exact
          path="/kampanje/veien-til-solgt/stavanger/stylist"
          component={VeienTilSolgtStvgStylist}
        />
        <Route
          exact
          path="/kampanje/detaljene-som-selger"
          component={SkalDuSelge}
        />
        <Route
          exact
          path="/kampanje/detaljene-som-selger/artikkel1"
          component={SkalDuSelgeArtikkel1}
        />
        <Route
          exact
          path="/kampanje/detaljene-som-selger/artikkel2"
          component={SkalDuSelgeArtikkel2}
        />
        <Route
          exact
          path="/kampanje/detaljene-som-selger/artikkel3"
          component={SkalDuSelgeArtikkel3}
        />
        <Route
          exact
          path="/kampanje/detaljene-som-selger/artikkel4"
          component={SkalDuSelgeArtikkel4}
        />
        <Route
          exact
          path="/kampanje/forstegangsselgeren"
          component={Forstegangsselgeren}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren-meta"
          component={Forstegangsselgerenmeta}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren/alle"
          component={ForstegangsselgerenHovedside}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren/oppussingsobjekt"
          component={ForstegangsselgerenOppusingsObjekt}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren/flytte-til-annen-by"
          component={ForstegangsselgerenFlytte}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren/arv-hytte"
          component={ForstegangsselgerenArv}
        />
        <Route
          exact
          path="/kampanje/Forstegangsselgeren/skilsmisse-samlivsbrudd"
          component={ForstegangsselgerenSkillsmisse}
        />

        <Route component={NoMatch} />
      </Switch>
    </>
  );
};

export default Kampanje;
